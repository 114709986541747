<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-flex xs12 sm4 md3 class="px-1">
            <date-picker
              v-model="table.criteria.beginDate"
              max-width="290px"
              :max="table.criteria.endDate"
              :min="minBeginDate"
              :label="$t('global.beginDate')"
              :clearable="false"
            />
          </v-flex>
          <v-flex xs12 sm4 md3 class="px-1">
            <date-picker
              v-model="table.criteria.endDate"
              max-width="290px"
              :max="maxEndDate"
              :min="table.criteria.beginDate"
              :label="$t('global.endDate')"
              :clearable="false"
            />
          </v-flex>
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>

        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td class="text-xs-left no-wrap">
                {{ $helpers.formatDate(row.item.date, "DD MMMM YYYY") }}
              </td>
              <td class="text-xs-center">
                <v-chip small class="ma-2" :color="$helpers.getStatusTypeColor(row.item)" label text-color="white">
                  <v-icon left size="small">{{ $helpers.getTypeIcon(row.item) }}</v-icon>
                  {{ $helpers.getTypeName(row.item) }}
                </v-chip>
              </td>
              <td class="text-xs-left">
                {{ $helpers.formattedClock(row.item.timesheet.clockInTimestamp) }}
              </td>
              <td class="text-xs-left">
                {{ $helpers.formattedClock(row.item.timesheet.clockOutTimestamp) }}
              </td>

              <td class="text-xs-left">
                {{ $helpers.convertMinutesToTime(row.item.timesheet.shift.expectedWorkingTimeInMinutes) }}
              </td>
              <td class="text-xs-left ">
                {{ $helpers.convertMinutesToTime(row.item.timesheet.actualWorkingTimeInMinutes) }}
              </td>
              <td class="text-xs-left no-wrap">
                <template v-if="row.item.overtimeDisabled">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary">
                        mdi-calendar-remove
                      </v-icon>
                    </template>
                    <span class="font-weight-bold">{{ $t("shift.not_included_overtime_calculation") }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  {{ $helpers.convertMinutesToTime(row.item.timesheet.totalOvertimeInMinutes) }}
                </template>
              </td>
              <td class="text-xs-left no-wrap">
                <template v-if="row.item.missingTimeDisabled">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary">
                        mdi-calendar-remove
                      </v-icon>
                    </template>
                    <span class="font-weight-bold">
                      {{ $t("shift.not_included_missing_time_calculation") }}
                    </span>
                  </v-tooltip>
                </template>
                <template v-else>
                  {{ $helpers.convertMinutesToTime(row.item.timesheet.totalMissingTimeInMinutes) }}
                </template>
              </td>
            </tr>
          </template>
          <template #body.append="{headers}">
            <tr>
              <td colspan="4" class="font-weight-bold primary--text">
                {{ $t("time.timesheet.total") }}
              </td>
              <td class="font-weight-bold primary--text">
                {{ $helpers.convertMinuteToHour(table.expectedWorkingTimeInMinutes) }}
              </td>
              <td class="font-weight-bold primary--text">
                {{ $helpers.convertMinuteToHour(table.actualWorkingTimeInMinutes) }}
              </td>
              <td class="font-weight-bold primary--text">
                {{ $helpers.convertMinuteToHour(table.totalOvertimeInMinutes) }}
              </td>
              <td class="font-weight-bold primary--text">
                {{ $helpers.convertMinuteToHour(table.totalMissingTimeInMinutes) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description shifts Component Logic
   * @date 25.07.2020
   */
  import { WORKPLANS_PAGINATE, FILTER_WORKPLANS } from "../query";
  import TimesheetRow from "./../model/TimesheetRow";

  export default {
    name: "shifts",
    data: vm => ({
      beginDateMenu: false,
      date: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM"),
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [vm.$store.state.auth.user.id],
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "date",
          descending: false
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("time.timesheet.date"),
            title: vm.$t("time.timesheet.date"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.status"),
            title: vm.$t("time.timesheet.status"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.clock_in"),
            title: vm.$t("time.timesheet.clock_in_title"),
            value: "clockInTimestamp",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.clock_out"),
            title: vm.$t("time.timesheet.clock_out_title"),
            value: "clockOutTimestamp",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },

          {
            text: vm.$t("time.timesheet.ewt"),
            title: vm.$t("time.timesheet.ewt_title"),
            value: "expectedWorkingTimeInMinutes",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.awt"),
            title: vm.$t("time.timesheet.awt_title"),
            value: "actualWorkingTimeInMinutes",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.ot"),
            title: vm.$t("time.timesheet.ot_title"),
            value: "totalOvertimeInMinutes",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.mt"),
            value: "totalMissingTimeInMinutes",
            title: vm.$t("time.timesheet.mt_title"),
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          }
        ],
        expectedWorkingTimeInMinutes: 0,
        actualWorkingTimeInMinutes: 0,
        totalOvertimeInMinutes: 0,
        totalMissingTimeInMinutes: 0
      },
      filtering: false
    }),
    computed: {
      minBeginDate() {
        return this.$moment(this.table.criteria.endDate)
          .subtract(30, "days")
          .format("YYYY-MM-DD");
      },
      maxEndDate() {
        return this.$moment(this.table.criteria.beginDate)
          .add(30, "days")
          .format("YYYY-MM-DD");
      }
    },
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
          this.filterWorkplans();
        },
        deep: true
      },
      date: {
        handler(v) {
          this.table.criteria.beginDate = this.$moment(v)
            .startOf("month")
            .format("YYYY-MM-DD");
          this.table.criteria.endDate = this.$moment(v)
            .endOf("month")
            .format("YYYY-MM-DD");
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
          this.filterWorkplans();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
        this.filterWorkplans();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: WORKPLANS_PAGINATE,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            },
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.table.data = [];
              data.filterWithPaginateWorkplans.content.forEach(currentWorkplan => {
                if (currentWorkplan.calculatedTimesheet) {
                  let timesheetRowModel = new TimesheetRow();
                  let currentWorkplanTimesheet = currentWorkplan.calculatedTimesheet;
                  timesheetRowModel.id = currentWorkplan.id;
                  timesheetRowModel.date = currentWorkplan.date;
                  timesheetRowModel.timesheet.id = currentWorkplanTimesheet.id;
                  timesheetRowModel.timesheet.shift.time =
                    currentWorkplan.assignedShifts.length === 0
                      ? "-- : --"
                      : currentWorkplanTimesheet.shift.shiftType === "FIX"
                      ? currentWorkplanTimesheet.shift.beginTime + " - " + currentWorkplanTimesheet.shift.endTime
                      : this.$helpers.convertMinutesToTime(currentWorkplanTimesheet.shift.totalWorkingTimeInMinutes);
                  timesheetRowModel.timesheet.shift.expectedWorkingTimeInMinutes =
                    currentWorkplan.assignedShifts.length !== 0
                      ? currentWorkplanTimesheet.shift.expectedWorkingTimeInMinutes
                      : 0;
                  timesheetRowModel.timesheet.shift.type =
                    currentWorkplan.assignedShifts.length === 0 ? "OFF_DAY" : currentWorkplanTimesheet.shift.shiftType;
                  timesheetRowModel.timesheet.actualWorkingTimeInMinutes =
                    currentWorkplanTimesheet.actualWorkingTimeInMinutes;
                  timesheetRowModel.timesheet.clockInTimestamp = currentWorkplanTimesheet.clockInTimestamp;
                  timesheetRowModel.timesheet.clockOutTimestamp = currentWorkplanTimesheet.clockOutTimestamp;
                  timesheetRowModel.assignedShifts = currentWorkplan.assignedShifts;
                  timesheetRowModel.countAsOvertime = currentWorkplan.countAsOvertime;
                  timesheetRowModel.leaveRequest = currentWorkplan.leaveRequest;
                  timesheetRowModel.timesheet.totalOvertimeInMinutes = currentWorkplanTimesheet.totalOvertimeInMinutes;
                  timesheetRowModel.timesheet.totalMissingTimeInMinutes =
                    currentWorkplanTimesheet.totalMissingTimeInMinutes;
                  this.table.data.push(timesheetRowModel);
                } else {
                  let timesheetRowModel = new TimesheetRow();
                  const assignedShift = currentWorkplan.assignedShifts[0];
                  timesheetRowModel.id = currentWorkplan.id;
                  timesheetRowModel.date = currentWorkplan.date;
                  timesheetRowModel.timesheet.shift.expectedWorkingTimeInMinutes = assignedShift
                    ? assignedShift.expectedWorkingTimeInMinutes
                    : 0;
                  timesheetRowModel.assignedShifts = currentWorkplan.assignedShifts;
                  timesheetRowModel.countAsOvertime = currentWorkplan.countAsOvertime;
                  timesheetRowModel.leaveRequest = currentWorkplan.leaveRequest;
                  this.table.data.push(timesheetRowModel);
                }
              });
              this.table.totalItems = data.filterWithPaginateWorkplans.pageInfo.totalElements || 0;
              this.table.totalPages = data.filterWithPaginateWorkplans.pageInfo.totalPages || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message, "error", true);
          })
          .finally(() => (this.table.loading = false));
      },
      filterWorkplans() {
        this.filtering = true;
        this.table.actualWorkingTimeInMinutes = 0;
        this.table.expectedWorkingTimeInMinutes = 0;
        this.table.totalOvertimeInMinutes = 0;
        this.table.totalMissingTimeInMinutes = 0;
        this.$apollo
          .query({
            query: FILTER_WORKPLANS,
            variables: {
              criteria: this.table.criteria
            },
            cachePolicy: "no-cache"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              data.filterWorkplans.forEach(workplan => {
                if (workplan.calculatedTimesheet) {
                  this.table.actualWorkingTimeInMinutes += workplan.calculatedTimesheet.actualWorkingTimeInMinutes;
                  this.table.expectedWorkingTimeInMinutes += workplan.calculatedTimesheet.shift
                    ? workplan.calculatedTimesheet.shift.expectedWorkingTimeInMinutes
                    : 0;
                  this.table.totalOvertimeInMinutes += workplan.calculatedTimesheet.totalOvertimeInMinutes;
                  this.table.totalMissingTimeInMinutes += workplan.calculatedTimesheet.totalMissingTimeInMinutes;
                } else {
                  const assignedShift = workplan.assignedShifts[0];
                  this.table.expectedWorkingTimeInMinutes += assignedShift
                    ? assignedShift.expectedWorkingTimeInMinutes
                    : 0;
                }
              });
            }
          })
          .catch(error => {
            this.$helpers.showNotification(error.message, "error", true);
          })
          .finally(() => (this.filtering = false));
      }
    }
  };
</script>

<style scoped></style>
