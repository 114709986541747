export default class TimesheetRow {
  constructor() {
    this.id = "";
    this.date = null;
    this.countAsOvertime = false;
    this.assignedShifts = [];
    this.employee = {
      id: "",
      firstName: "",
      lastName: "",
      profile: { imageUrl: null },
      staffNumber: "-",
      branch: { name: "-" },
      unit: { name: "-" },
      team: { name: "-" },
      jobInfo: { name: "-" }
    };
    this.timesheet = {
      id: "",
      shift: { time: "--:--", expectedWorkingTimeInMinutes: "-", type: null },
      clockInTimestamp: null,
      clockOutTimestamp: null,
      latelyClockInTimeInMinutes: null,
      earlyClockOutTimeInMinutes: null,
      clockInIdentityType: null,
      clockOutIdentityType: null,
      clockInTerminal: "-",
      clockOutTerminal: "-",
      actualWorkingTimeInMinutes: "-",
      totalOvertimeInMinutes: null,
      totalMissingTimeInMinutes: null
    };
  }
}
