import gql from "graphql-tag";

export const SHIFT_PAGINATE = gql`
  query FilterWithPaginateWorkplans($criteria: WorkplanFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateWorkplans(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
        pageNumber
      }
      content {
        id
        date
        countAsOvertime
        assignedShifts {
          id
          name
          beginTime
          endTime
          shiftType
          totalWorkingTimeInMinutes
          expectedWorkingTimeInMinutes
        }
      }
    }
  }
`;

export const WORKPLANS_PAGINATE = gql`
  query FilterWithPaginateWorkplans($criteria: WorkplanFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateWorkplans(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
        pageNumber
      }
      content {
        id
        date
        countAsOvertime
        overtimeDisabled
        missingTimeDisabled
        assignedShifts {
          id
          expectedWorkingTimeInMinutes
        }
        leaveRequest {
          id
          leaveType {
            color
            icon
            name
          }
        }
        timesheets {
          id
          actualWorkingTimeInMinutes
          clockInTimestamp
          clockOutTimestamp
          shift {
            beginTime
            endTime
            shiftType
            totalWorkingTimeInMinutes
            expectedWorkingTimeInMinutes
          }
          totalOvertimeInMinutes
          totalMissingTimeInMinutes
        }
        calculatedTimesheet {
          id
          actualWorkingTimeInMinutes
          clockInTimestamp
          clockOutTimestamp
          latelyClockInTimeInMinutes
          earlyClockOutTimeInMinutes
          clockInIdentityType
          clockOutIdentityType
          totalOvertimeInMinutes
          totalMissingTimeInMinutes
          firstClockIn {
            id
          }
          lastClockOut {
            id
          }
          clockInTerminal {
            name
          }
          clockInAccessTerminal {
            name
          }
          clockOutTerminal {
            name
          }
          clockOutAccessTerminal {
            name
          }
          shift {
            id
            name
            color
            beginTime
            endTime
            shiftType
            lengthInMinutes
            expectedWorkingTimeInMinutes
            totalWorkingTimeInMinutes
            totalBreakTimeInMinutes
            latelyClockInTolerance
            earlyClockOutTolerance
            maxClockOutOvertimeInMinutes
            maxClockInOvertimeInMinutes
          }
        }
      }
    }
  }
`;

export const OVERTIME_APPROVAL = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      pendingApprovalOvertimeRequests {
        id
        overtime {
          requestStatus
          requestedDateTime
          workplan {
            date
          }
          overtimeInMinutes
          reason
          description
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const EMPLOYEE_MANAGERS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      timeSettings {
        overtimeApprover
        shiftApprover
      }
    }
  }
`;

export const FILTER_WORKPLANS = gql`
  query filterWorkplans($criteria: WorkplanFilterCriteria!) {
    filterWorkplans(criteria: $criteria) {
      id
      date
      countAsOvertime
      overtimeDisabled
      missingTimeDisabled
      assignedShifts {
        id
        expectedWorkingTimeInMinutes
      }
      leaveRequest {
        id
        leaveType {
          color
          icon
          name
        }
      }
      timesheets {
        id
        actualWorkingTimeInMinutes
        clockInTimestamp
        clockOutTimestamp
        shift {
          beginTime
          endTime
          shiftType
          totalWorkingTimeInMinutes
          expectedWorkingTimeInMinutes
        }
        totalOvertimeInMinutes
        totalMissingTimeInMinutes
      }
      calculatedTimesheet {
        id
        actualWorkingTimeInMinutes
        clockInTimestamp
        clockOutTimestamp
        latelyClockInTimeInMinutes
        earlyClockOutTimeInMinutes
        clockInIdentityType
        clockOutIdentityType
        totalOvertimeInMinutes
        totalMissingTimeInMinutes
        firstClockIn {
          id
        }
        lastClockOut {
          id
        }
        clockInTerminal {
          name
        }
        clockInAccessTerminal {
          name
        }
        clockOutTerminal {
          name
        }
        clockOutAccessTerminal {
          name
        }
        shift {
          id
          name
          color
          beginTime
          endTime
          shiftType
          lengthInMinutes
          expectedWorkingTimeInMinutes
          totalWorkingTimeInMinutes
          totalBreakTimeInMinutes
          latelyClockInTolerance
          earlyClockOutTolerance
          maxClockOutOvertimeInMinutes
          maxClockInOvertimeInMinutes
        }
      }
    }
  }
`;
